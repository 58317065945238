import {
  GetterTree, MutationTree, Module, ActionTree,
} from 'vuex'
import { $themeConfig } from '@themeConfig'
import axios from '@axios'
import { RootState } from '../types'
import { VerticalMenuState } from './types'

const userData = JSON.parse(localStorage.getItem('userData') || '{}')

const state: VerticalMenuState = {
  isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
  isUserRole: userData?.role || null,
  defaultProgram: localStorage.getItem('defaultProgram') || null,
  programOptions: [],
}

const getters: GetterTree<VerticalMenuState, RootState> = {
  getUserRole: state => state.isUserRole,
  getDefaultProgram: state => state.defaultProgram,
  getProgramOptions: state => state.programOptions,
}

const mutations: MutationTree<VerticalMenuState> = {
  UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val
  },
  UPDATE_USER_ROLE(state, val) {
    state.isUserRole = val
  },
  UPDATE_DEFAULT_PROGRAM(state, val) {
    localStorage.setItem('defaultProgram', val)
    state.defaultProgram = val
  },
  SET_PROGRAM_OPTIONS(state, val) {
    state.programOptions = val
  },
}

const actions: ActionTree<VerticalMenuState, RootState> = {
  async fetchProgramOptions({ commit }, queryParams) {
    try {
      const response = await axios.get('/auth/programs', { params: queryParams })

      const programOptions = response.data.data.reduce((arr: Array<object>, item: {id: string, name: string}) => {
        arr.push({ id: item.id, label: item.name })
        return arr
      }, [])

      commit('SET_PROGRAM_OPTIONS', programOptions)
      return response
    } catch (error) {
      return error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
