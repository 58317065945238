import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import VueTheMask from 'vue-the-mask'

import instanceSessionCamperNames from '@core/mixins/instanceSessionCamperNames'
import i18n from '@/libs/i18n'
import router from '@/router'
import store from '@/store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import '@/libs/quill'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueTheMask)

// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin(instanceSessionCamperNames)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

if (['production', 'stage'].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENVIRONMENT,
    release: `${process.env.VUE_APP_NAME}`,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [process.env.VUE_APP_ROOT_API, /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
    logErrors: true,
  })
}

import(/* webpackChunkName: "config" */ '../site-config.json')
  .then(r => {
    Vue.prototype.$config = r.default
  })
  .finally(() => {
    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  })
